import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./app.css";
import Rolle from "./config/roles_list";
import * as Sentry from "@sentry/react";
import Spinner from "react-bootstrap/esm/Spinner";
import IdleTimeOutHandler from "./components/IdleTimeOut/IdleTimeOutHandler";
const ResetPasswordForm = React.lazy(() =>
  lazyRetry(() => import("./components/ResetPasswordForm"), "ResetPasswordForm")
);
const ForgotPasswordForm = React.lazy(() =>
  lazyRetry(() => import("./components/ForgotPasswordForm"), "ForgotPasswordForm")
);
const AnamneseTabelle = React.lazy(() =>
  lazyRetry(() => import("./pages/anamnese/AnamneseTabelle"), "AnamneseTabelle")
);
const BenutzerTabelle = React.lazy(() =>
  lazyRetry(() => import("./pages/benutzer/BenutzerTabelle"), "BenutzerTabelle")
);
const ÄnderungsprotokollTabelle = React.lazy(() =>
  lazyRetry(() => import("./pages/änderungsprotokoll/ÄnderungsprotokollTabelle"), "ÄnderungsprotokollTabelle")
);
const ÄnderungsprotokollAnlegen = React.lazy(() =>
  lazyRetry(() => import("./pages/änderungsprotokoll/anlegen"), "ÄnderungsprotokollAnlegen")
);
const ArztTabelle = React.lazy(() => lazyRetry(() => import("./pages/arzt/ArztTabelle"), "ArztTabelle"));
const ArztAnlegen = React.lazy(() => lazyRetry(() => import("./pages/arzt/anlegen"), "ArztAnlegen"));
const PflegedienstTabelle = React.lazy(() =>
  lazyRetry(() => import("./pages/pflegedienst/PflegedienstTabelle"), "PflegedienstTabelle")
);
const PflegedienstAnlegen = React.lazy(() =>
  lazyRetry(() => import("./pages/pflegedienst/anlegen"), "PflegedienstAnlegen")
);
const KrankenkasseTabelle = React.lazy(() =>
  lazyRetry(() => import("./pages/krankenkasse/KrankenkasseTabelle"), "KrankenkasseTabelle")
);
const KrankenkasseAnlegen = React.lazy(() =>
  lazyRetry(() => import("./pages/krankenkasse/anlegen"), "KrankenkasseAnlegen")
);
const Home = React.lazy(() => lazyRetry(() => import("./pages/home/home"), "Home"));
const Navbar = React.lazy(() => lazyRetry(() => import("./components/navbar/Navbar"), "Navbar"));
const PatientAnlegen = React.lazy(() => lazyRetry(() => import("./pages/patient/anlegen"), "PatientAnlegen"));
const AnamneseAnlegen = React.lazy(() => lazyRetry(() => import("./pages/anamnese/anlegen"), "AnamneseAnlegen"));
const VerlaufdokuTabelle = React.lazy(() =>
  lazyRetry(() => import("./pages/verlaufsdoku/VerlaufdokuTabelle"), "VerlaufdokuTabelle")
);
const VerlaufsDokuAnlegen = React.lazy(() =>
  lazyRetry(() => import("./pages/verlaufsdoku/anlegen"), "VerlaufsDokuAnlegen")
);
const BenuterAnlegen = React.lazy(() => lazyRetry(() => import("./pages/benutzer/anlegen"), "BenuterAnlegen"));
const Login = React.lazy(() => lazyRetry(() => import("./pages/login/login"), "Login"));
const RequireAuth = React.lazy(() => lazyRetry(() => import("./components/RequireAuth"), "RequireAuth"));
const PersistLogin = React.lazy(() => lazyRetry(() => import("./components/PersistLogin"), "PersistLogin"));
const MyToast = React.lazy(() => lazyRetry(() => import("./components/toast/toast"), "MyToast"));
const Layout = React.lazy(() => lazyRetry(() => import("./components/Layout"), "Layout"));
const Missing = React.lazy(() => lazyRetry(() => import("./components/Missing"), "Missing"));
const Unauthorized = React.lazy(() => lazyRetry(() => import("./components/Unauthorized"), "Unauthorized"));
const PDF = React.lazy(() => lazyRetry(() => import("./pages/pdf/pdf"), "PDF"));
const PatientenVerstorbenTabelle = React.lazy(() =>
  lazyRetry(() => import("./pages/patient/PatientenVerstorbenTabelle"), "PatientenVerstorbenTabelle")
);
const PatientenWundenverheiltTabelle = React.lazy(() =>
  lazyRetry(() => import("./pages/patient/PatientenWundenverheiltTabelle"))
);
const PatientenTabelle = React.lazy(() =>
  lazyRetry(() => import("./pages/patient/PatientenTabelle"), "PatientenTabelle")
);
const PasswortÄndern = React.lazy(() => lazyRetry(() => import("./components/PasswortÄndern"), "PasswortÄndern"));
const PatientenInaktivTabelle = React.lazy(() =>
  lazyRetry(() => import("./pages/patient/PatientenInaktivTabelle"), "PatientenInaktivTabelle")
);
const LicensesTabelle = React.lazy(() => lazyRetry(() => import("./pages/licenses/licenses"), "licenses"));

// a function to retry loading a chunk to avoid chunk load error for out of date code
const lazyRetry = function (componentImport, name) {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(`retry-${name}-refreshed`) || "false");
    // try to import the component
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(`retry-${name}-refreshed`, "false"); // success so reset the refresh
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(`retry-${name}-refreshed`, "true"); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

function App() {
  const [list, setList] = useState([]);

  const Nachricht = (props) => {
    const { title, description, description2 } = props;
    let toastProperties = null;
    const id = Math.floor(Math.random() * 100 + 1);

    toastProperties = {
      id,
      title: title,
      description: description,
      description2: description2,
    };

    setList([...list, toastProperties]);
  };

  const Loading = () => {
    return (
      <div className="position-absolute top-50 start-50 translate-middle">
        <Spinner className="m-3" width="400" height="400" animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  };

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback={<Loading />}>
              <Layout />
            </React.Suspense>
          }
        >
          {/* public routes  */}
          <Route
            path="login"
            element={
              <React.Suspense fallback={<Loading />}>
                <Login Nachricht={Nachricht} />
              </React.Suspense>
            }
          />
          <Route
            path="reset-passwort"
            element={
              <React.Suspense fallback={<Loading />}>
                <ResetPasswordForm Nachricht={Nachricht} />
              </React.Suspense>
            }
          />
          <Route
            path="forgot-passwort"
            element={
              <React.Suspense fallback={<Loading />}>
                <ForgotPasswordForm Nachricht={Nachricht} />
              </React.Suspense>
            }
          />
          <Route
            path="unauthorized"
            element={
              <React.Suspense fallback={<Loading />}>
                <Unauthorized />
              </React.Suspense>
            }
          />

          {/* catch all */}
          <Route
            path="*"
            element={
              <React.Suspense fallback={<Loading />}>
                <Missing />
              </React.Suspense>
            }
          />

          {/* protected routes */}
          <Route
            element={
              <React.Suspense fallback={<Loading />}>
                <PersistLogin />
              </React.Suspense>
            }
          >
            <Route
              element={
                <React.Suspense fallback={<Loading />}>
                  <RequireAuth
                    allowedRoles={[
                      Rolle.NormalerBenutzer,
                      Rolle.Teamleitung,
                      Rolle.Admin,
                      Rolle.Chef,
                      Rolle.Entwickler,
                    ]}
                  />
                </React.Suspense>
              }
            >
              <Route
                path="/"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <Home Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="/passwortaendern"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <PasswortÄndern Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="/licenses"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <LicensesTabelle Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="patienten"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <PatientenTabelle Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="patienten/verstorben"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <PatientenVerstorbenTabelle Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="patienten/inaktiv"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <PatientenInaktivTabelle Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="patienten/wundeverheilt"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <PatientenWundenverheiltTabelle Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="patienten/ansicht/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <PatientAnlegen edit={false} ansicht={true} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="anamnese/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <AnamneseTabelle Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="anamnese/ansicht/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <AnamneseAnlegen edit={false} ansicht={true} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="verlaufsdoku/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <VerlaufdokuTabelle Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="verlaufsdoku/ansicht/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <VerlaufsDokuAnlegen edit={false} ansicht={true} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="arzt"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <ArztTabelle Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="arzt/ansicht/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <ArztAnlegen edit={false} ansicht={true} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="pflegedienst"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <PflegedienstTabelle Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="pflegedienst/ansicht/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <PflegedienstAnlegen edit={false} ansicht={true} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="krankenkasse"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <KrankenkasseTabelle Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="krankenkasse/ansicht/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <KrankenkasseAnlegen edit={false} ansicht={true} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
            </Route>
            <Route
              element={
                <React.Suspense fallback={<Loading />}>
                  <RequireAuth
                    allowedRoles={[Rolle.NormalerBenutzer, Rolle.Teamleitung, Rolle.Admin, Rolle.Entwickler]}
                  />
                </React.Suspense>
              }
            >
              <Route
                path="arzt/anlage"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <ArztAnlegen edit={false} ansicht={false} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="pflegedienst/anlage"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <PflegedienstAnlegen edit={false} ansicht={false} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="krankenkasse/anlage"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <KrankenkasseAnlegen edit={false} ansicht={false} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="patienten/anlage"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <PatientAnlegen edit={false} ansicht={false} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="patienten/bearbeiten/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <PatientAnlegen edit={true} ansicht={false} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="arzt/bearbeiten/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <ArztAnlegen edit={true} ansicht={false} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="pflegedienst/bearbeiten/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <PflegedienstAnlegen edit={true} ansicht={false} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="krankenkasse/bearbeiten/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <KrankenkasseAnlegen edit={true} ansicht={false} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="anamnese/anlage/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <AnamneseAnlegen edit={false} ansicht={false} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="anamnese/bearbeiten/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <AnamneseAnlegen edit={true} ansicht={false} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="anamnese/kopieren/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <AnamneseAnlegen edit={false} ansicht={false} copy={true} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="verlaufsdoku/anlage/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <VerlaufsDokuAnlegen edit={false} ansicht={false} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="verlaufsdoku/bearbeiten/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <VerlaufsDokuAnlegen edit={true} ansicht={false} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="verlaufsdoku/kopieren/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <VerlaufsDokuAnlegen edit={false} ansicht={false} copy={true} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="pdf/:anamneseid"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <PDF nachdruck={false} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="pdf/nachdruck/:verlaufsdokuid"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <PDF nachdruck={true} />
                    </>
                  </React.Suspense>
                }
              />
            </Route>
            <Route
              element={
                <React.Suspense fallback={<Loading />}>
                  <RequireAuth allowedRoles={[Rolle.Admin, Rolle.Chef, Rolle.Teamleitung, Rolle.Entwickler]} />
                </React.Suspense>
              }
            >
              <Route
                path="benutzer"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <BenutzerTabelle Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
            </Route>
            <Route
              element={
                <React.Suspense fallback={<Loading />}>
                  <RequireAuth allowedRoles={[Rolle.Admin, Rolle.Chef, Rolle.Entwickler]} />
                </React.Suspense>
              }
            >
              <Route
                path="benutzer/anlage"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <BenuterAnlegen edit={false} ansicht={false} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="benutzer/bearbeiten/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <BenuterAnlegen edit={true} ansicht={false} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="benutzer/ansicht/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <BenuterAnlegen edit={false} ansicht={true} Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="änderungsprotokoll"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <ÄnderungsprotokollTabelle Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
              <Route
                path="änderungsprotokoll/ansicht/:id"
                element={
                  <React.Suspense fallback={<Loading />}>
                    <>
                      <Navbar />
                      <ÄnderungsprotokollAnlegen Nachricht={Nachricht} />
                    </>
                  </React.Suspense>
                }
              />
            </Route>
          </Route>
        </Route>
      </Routes>
      <MyToast toastList={list} setList={setList} />
      <IdleTimeOutHandler />
    </>
  );
}

export default Sentry.withProfiler(App);
