import React, { useEffect, useState } from "react";
import moment from "moment";
import { IdleTimeOutModal } from "./IdleTimeOutModal";

const IdleTimeOutHandler = () => {
  const [showModal, setShowModal] = useState(false);

  const handleContinueSession = () => {
    setShowModal(false);
    window.location.reload();
  };

  useEffect(() => {
    let timer = undefined;
    var MinutenBisInaktiv = 30;
    const events = ["click", "scroll", "load", "keydown", "mousemove", "resize"];

    const addEvents = () => {
      events.forEach((eventName) => {
        window.addEventListener(eventName, eventHandler);
      });

      startTimer();
    };

    const removeEvents = () => {
      events.forEach((eventName) => {
        window.removeEventListener(eventName, eventHandler);
      });
    };

    const startTimer = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        let lastInteractionTime = localStorage.getItem("lastInteractionTime");
        const diff = moment.duration(moment().diff(moment(lastInteractionTime)));
        let timeOutInterval = 1000 * 60 * MinutenBisInaktiv;

        if (diff._milliseconds < timeOutInterval) {
          startTimer();
        } else {
          setShowModal(true);
        }
      }, 1000 * 60 * MinutenBisInaktiv);
    };

    const eventHandler = (eventType) => {
      // console.log(eventType);
      localStorage.setItem("lastInteractionTime", moment());
      if (timer) {
        startTimer();
      }
    };

    addEvents();

    return () => {
      removeEvents();
      clearTimeout(timer);
    };
  }, []);

  return (
    <div>
      <IdleTimeOutModal showModal={showModal} handleContinue={handleContinueSession} />
    </div>
  );
};

export default IdleTimeOutHandler;
