import React from "react";
import { createRoot } from "react-dom/client";
import { Routes, Route, HashRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/browser";
import Package from "../package.json";

import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "./components/AuthProvider";

Sentry.init({
  enabled: process.env.NODE_ENV === "production",
  dsn: "https://11541687239e4397840b73662706c5ca@glitchtip.haedicke.one/2",
  environment: process.env.NODE_ENV,
  release: Package.version,
  integrations: [new BrowserTracing()],
  // // Error Report
  // beforeSend(event, hint) {
  //   // Check if it is an exception, and if so, show the report dialog
  //   if (event.exception) {
  //     Sentry.showReportDialog({ eventId: event.event_id });
  //   }
  //   return event;
  // },
  debug: false,
  attachStacktrace: true,
  autoSessionTracking: false,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: 3,
      staleTime: 5 * 60 * 1000,
    },
  },
});

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <HashRouter>
        <AuthProvider>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </AuthProvider>
      </HashRouter>
      <ReactQueryDevtools />
    </QueryClientProvider>
  </React.StrictMode>
);
