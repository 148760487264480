import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export const IdleTimeOutModal = ({ showModal, handleContinue }) => {
  return (
    <Modal show={showModal} onHide={handleContinue}>
      <Modal.Header closeButton>
        <Modal.Title>Sie sind schon lange inaktiv!</Modal.Title>
      </Modal.Header>
      <Modal.Body>Ihre Sitzung ist zeitlich abgelaufen. Sie möchten weiter machen?</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleContinue}>
          Sitzung fortfahren
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
